/* eslint-disable no-useless-escape */
import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { navigate } from "gatsby"

class NotFoundPage extends Component {

    componentDidMount() {

        const { location } = this.props,
            pathname = String(location.pathname).toLowerCase();

        if (redirects[ pathname ]) {
            navigate( redirects[ pathname ] );
        } else {
            
            for (let i = 0; i < redirectsRegexp.length; i += 1) {
                let rgx = new RegExp(redirectsRegexp[ i ].rgx, "i")
                if (pathname.match(rgx)) {
                    navigate(pathname.replace(rgx, redirectsRegexp[ i ].rule).toLowerCase());
                    break;
                }
            }

            const rules = Object.keys(redirects);
            for (let i = 0; i < rules.length; i += 1) {
                if (pathname.indexOf(rules[ i ]) === 0) {
                    navigate( redirects[ rules[ i ] ] );
                    break;
                }
            }

        }
    }

    render() {
        return (
            <Layout mainClassName="404" data={{}} noActiveMenu={true} translations={{}} pageContext={{
                slug: "404",
                prefix: "",
                locales: ["en"],
                lang: "en",
                translations: {},
                menuItems: []
            }}>
                <SEO title="404: Not found" lang="en">
                    <meta name="theme-color" content="#f3f3f3" />
                </SEO>
                <div className=" initial-padding">
                    <h1>404: Not Found</h1>
                    <p>You just hit a route that doesn&#39;t exist...</p>
                </div>
            </Layout>
        )
    }
}

export default NotFoundPage


const redirects = {
    "/en/teaching-materials/e118/the-prenuptial-agreement-between-catherine-zeta-jones-and-michael-douglas.axd":
        "/teaching/materials/behavioral-hypotheses/118-the-prenuptial-agreement-between-catherine-zeta-jones-and-michael-douglas"
    ,
    "/en/teaching-materials": "/teaching/materials",
    "/es/publicaciones": "/es/publicaciones",
    "/en/publications": "/publications"
};

const redirectsRegexp = [
    {
        rgx: "\/es\/publicaciones\/z\\d{1,4}\/(.*)\.axd",
        rule: "/es/publicaciones/$1"
    },
    {
        rgx: "\/en\/publications\/z\\d{1,4}\/(.*)\.axd",
        rule: "/en/publications/$1"
    },
    {
        rgx: "/(?:\\d{4})\/(?:\\d{2})\/(.*)",
        rule: "/$1"
    }
]